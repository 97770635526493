import {configureStore} from '@reduxjs/toolkit'
import authReducer from '../features/auth/authSlice'
import postReducer from '../features/post/postSlice'
import adminReducer from '../features/admin/adminSlice'

export const store = configureStore({
    reducer: {
      auth:authReducer,
      post:postReducer,
      admin:adminReducer
    },
  });